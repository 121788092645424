label.filter-label
{
    display: none;
}

.customer.row > div,
.users.row > div
{
    width: 100%;
}

table
{
    font-size: 12px !important;

    button
    {
        font-size: inherit !important ;
    }
}

.customers tbody tr,
.orders tbody tr,
.buys tbody tr,
.sells tbody tr
{
   cursor: pointer;
}

.customers tbody tr:hover,
.orders tbody tr:hover,
.buys tbody tr:hover,
.sells tbody tr:hover
{
    background-color: rgba(178, 196, 245, 0.288);
}

.selection-cell-header,
.selection-cell
{
    display: none;
}

.users .table-responsive,
.customer .table-responsive
{
    display: table;
   
}

.table-responsive
{
 border: none !important;
}

.trade table img
{
    max-width: 50px;
    max-height: 50px;
    object-fit: contain;
}
