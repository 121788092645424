@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}

body
{
  font-size: 14px;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

pre
{
  white-space: pre-wrap;
  word-break: keep-all;
  font-size: 0.85rem;
}

pre.blk-response
{
  background-color: #e6e6e6;
  padding: 20px;

  @media screen and (min-width:1024px)
  {
    padding-right: 18%;    
  }
}
