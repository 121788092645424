.login-logo {

    height: 120px;

    & img.rounded{
        object-fit: cover;
        width: 100% !important;
        height: 100% !important;
    }
}
