.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.passwordDiv
{
  position: relative;

  .showPassword
  {
    position: absolute;
    right: 0;
    top:34%;
    outline: none;;
    background: none !important;
    display: inline-block;
    margin-top: 10px;
    color: #000 !important;
    border: none;
  }
}
